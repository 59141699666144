$smallScreen:400px;
$tablet:768px;
$desktop:1024px;


@mixin tablet{
  @media (min-width:#{$tablet}){
    @content;
  }
}

@mixin smallScreen{
  @media(min-width:$smallScree){
    @content;
  }
}

@mixin desktop{
  @media (min-width: #{$desktop}) {
    @content;
  }
}

@mixin full-width{
  width:125%;
  margin-left:-12.5%;
}

@mixin folder-article{
  >div{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 10px;
    position:relative;
    h2{
      max-width:180px
    }
    &:not(:last-child){
      margin-bottom: 10px;
    }
    border-bottom:1px solid #737373;
    >div{
      margin-top: 10px;
      >*{   
       width:100%;
       margin-bottom: 10px;
      }
      .hidden{
        display: none;
      }
    }
  }
 
  @include tablet{
    >div{
      border:none;
      display: block;
      h2{
        margin:20px 0;
      }
      >div{
        display: block;
      }
    }
  }
}

@mixin article($width) {
  background-color: $main-gray;
  @include full-width;
  padding: 20px 12.5% 40px 12.5%;
  display: flex;
  flex-direction: column;
  @include tablet{
     flex-direction: row;
     flex-wrap: wrap;
      justify-content: space-between;
     >*{
       width:#{$width};
     }
  }
  @include desktop{
         flex-wrap:nowrap;
         justify-content: flex-start;
         >*{
       width:#{$width};
     }
        h2{
            font-family: "Playfair Display", sans-serif;
            font-size: 18px;
            font-weight: bold;
            width:65%;
            margin:15px 0;
        }
  }  
}
 

@mixin skew-aside{
  margin-top: 25px;
  transform: skewX(-35deg);
  background-color:$light-gray;
  >p{
    transform: skew(35deg);
    font-family: 'Playfair Display';
    font-style: italic;
    span{
      font-weight: 700;
    }
  }  
}

@mixin personne{
   >div>p>span{
      display: block;
      width:100%;
      text-align:center;
    }
  >article{
    @include article(31%);
    img{
      width:218px;
      height:auto;
    }
    &.hidden{
      display: none;
    }
  }
  @include tablet {
    >div>p>span{
      display: inline;
    }
    >article{
      display: flex !important;
      justify-content: space-between;
       img{
        width:100%
      }
    }
  }
  @include desktop{
     >article{
         >div:first-child{
             width: 28%;
         }
         >div{
             width:33%
         }
     }
    }
}

