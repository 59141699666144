.valeurIcon{
	 enable-background:new 0 0 113.6 112.1;
    .st0{fill:#1D1D1B;}
	.st1{fill:#FFFFFF;}
	.st2{fill:#9C9B9B;}
	.st3{fill:#F5F5F5;}
}

.liberteIcon{
	enable-background:new 0 0 113.6 112.1;
	.st0{fill:#AB0033;}
	.st1{fill:#C6C6C5;}
}

.equipe{
	 enable-background:new 0 0 113.6 112.1;
	.st0{fill:#FFFFFF;}
	.st1{fill:none;stroke:#FFFFFF;stroke-width:0.75;stroke-miterlimit:10;}
}

.machValueLogo{
	enable-background:new 0 0 376.8 155;
	.st0{fill:#1A171B;}
	.st1{fill:#C6C6C5;}
	.st2{fill:#AB0033;}
	.st3{fill:#636569;}

}

.boite_au_lettre{
	enable-background:new 0 0 113.6 112.1;
	.st0{fill:#AB0033;}
	.st1{fill:#631025;}
	.st2{fill:#C6C6C5;}
	cursor: pointer;
}

.position{
	enable-background:new 0 0 113.6 112.1;
	.st0{fill:none;stroke:#5A4538;stroke-width:0.7412;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st1{fill:#AB0033;}
	.st2{fill:#020203;}
	.st3{fill:#C7C7C6;}
	.st4{fill:none;stroke:#C7C7C6;stroke-miterlimit:10;}
	width:70px;
	height:70px;
}

.time-to-market{
	enable-background:new 0 0 113.6 112.1;
	.st0{fill:#FFFFFF;}
	.st1{fill:#AB0033;}
	.st2{fill:#A2A1A1;}
	width:70px;
	height:70px;
}

.audit{
	enable-background:new 0 0 113.6 112.1;
	.st0{fill:#AB0033;}
	.st1{fill:#C6C6C5;}
	.st2{fill:#A2A1A1;}
	.st3{fill:none;stroke:#AFAFAC;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st4{fill:none;stroke:#000000;stroke-miterlimit:10;}
	width:70px;
	height:70px;
}

.analyse{
	enable-background:new 0 0 113.6 112.1;
	.st0{fill:#FFFFFF;}
	.st1{fill:#C6C6C5;}
	.st2{fill:#AB0033;}
	width:70px;
	height:70px;
}

.remodeling{
	.st0{fill:#AB0033;}
	.st1{fill:none;stroke:#868686;stroke-width:2;stroke-miterlimit:10;}
	enable-background:new 0 0 113.6 112.1;
	width:70px;
	height:70px;
}

.lean{
	enable-background:new 0 0 113.6 112.1;
	.st0{fill:none;stroke:#594437;stroke-width:0.7412;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st1{fill:#AB0033;}
	.st2{fill:#9C9B9B;}
	.st3{fill:none;stroke:#9C9B9B;stroke-miterlimit:10;}
	.st4{fill:#C6C6C5;}
	width:70px;
	height:70px;
}

.dq{
	enable-background:new 0 0 113.6 112.1;
	.st0{fill:#C6C6C5;}
	.st1{fill:none;stroke:#AB0033;stroke-width:2;stroke-miterlimit:10;}
	width:70px;
	height:70px;
}

.prospection{
	 enable-background:new 0 0 113.6 112.1;
	.st0{fill:#B1B1B1;}
	.st1{fill:#AB0033;}
	.st2{fill:#1A171B;}
	width:70px;
	height:70px;
}

.methodo{
	enable-background:new 0 0 113.6 112.1;
	.st0{fill:#AB0033;}
	.st1{fill:#A2A1A1;}
	.st2{fill:none;stroke:#1D1D1B;stroke-miterlimit:10;}
	width:70px;
	height:70px;
}

.portrait{
	enable-background:new 0 0 113.6 112.1;
	.st0{fill:#AB0033;}
	width:26px;
	height:26px;
}

.machvalue-home{
	.st0{fill:#636569;}
	.st1{fill:#1A171B;}
	.st2{fill:#AB0033;}
	.st3{fill:#C6C6C5;}
	enable-background:new 0 0 476.3 348;
	width:200px;
	height:130px;
}

.machValueLogo{
	transition:0.3s;
	width:290px;
}